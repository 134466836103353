// src/shared/services/api/axiosBackendInstance.ts
import axios from 'axios';
import toast from 'react-hot-toast';

const { REACT_APP_WEB_API } = process.env;

// Create Axios instance
const axiosBackendApi = axios.create({
  baseURL: REACT_APP_WEB_API,
});

// Set default headers
axiosBackendApi.defaults.headers.common['Accept'] = 'application/json';
axiosBackendApi.defaults.headers.common['Content-Type'] = 'application/json';
axiosBackendApi.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(localStorage.getItem('user') || '{}')?.token;

// Request interceptor
axiosBackendApi.interceptors.request.use((config) => {
  // Adding default parameters here if needed
  config.params = {
    ...config.params,
  };
  return config;
});

// Response interceptor
axiosBackendApi.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      // Specific error handling for 401
      if (error.response.status === 401) {
        console.error('Error 401: Redirecting to login');

        // Show toast notification for session expiration
        toast.error('Session expired, please login again.', {
          duration: 4000,
          position: "bottom-center",
          style: {
            border: '1px solid #713200',
            padding: '16px',
            color: '#713200',
          },
        });

        // Log out the user and redirect to login
        handleLogout();
        return Promise.reject(error);
      }
    } else if (error.request) {
      console.error('Backend Network Error:', error.request);
    } else {
      console.error('Error without response:', error.message);
    }

    // Show toast notification for general errors
    toast.error('An error occurred', {
      duration: 4000,
      position: "bottom-center",
      style: {
        border: '1px solid #713200',
        padding: '16px',
        color: '#713200',
      },
    });
    return Promise.reject(error);
  }
);

// Function to handle logout
const handleLogout = async () => {
  // Clear local storage
  await axiosBackendApi.post('admin/user/auth/logout').then((response) => {
    localStorage.removeItem('user');
    // Redirect to login page
    window.location.href = '/login';
  });
  // Adjust the URL as necessary
};

export default axiosBackendApi;
