import MarkdownIt from 'markdown-it';
import hljs from 'highlight.js';
import 'highlight.js/styles/github-dark.css'; // Use any preferred style
import markdownItHighlightjs from 'markdown-it-highlightjs';

// Initialize markdown-it with the highlightjs plugin
const md = new MarkdownIt().use(markdownItHighlightjs, { hljs });

const MarkdownRenderer = ({ markdownText }) => {
  const safeMarkdownText = typeof markdownText === 'string' ? markdownText : '';
  return (
    <div
      className="markdown-container"
      style={{ fontFamily: '"Fira Code", Consolas, Menlo, Monaco, "Courier New", monospace' }}
      dangerouslySetInnerHTML={{ __html: md.render(safeMarkdownText) }}
    />
  );
};

export default MarkdownRenderer;
