import React, { useState, useEffect } from "react";

type GlobalMessageProps = {
  message: string; // Message to display
  open: boolean; // Control visibility
  onClose: () => void; // Callback to handle closing the message
  duration?: number; // Duration in milliseconds (optional)
};

const GlobalMessage: React.FC<GlobalMessageProps> = ({
                                                       message,
                                                       open,
                                                       onClose,
                                                       duration = 3000,
                                                     }) => {
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (open) {
      // Auto-close after the duration
      timer = setTimeout(() => {
        onClose();
      }, duration);
    }
    return () => clearTimeout(timer);
  }, [open, onClose, duration]);

  return (
    <div className={`message-container ${open ? "visible" : "hidden"}`}>

        <span className="message-text">{message}</span>
        <button className="close-button" onClick={onClose}>
          ✖
        </button>

    </div>
  );
};

export default GlobalMessage;
