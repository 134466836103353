import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  MenuItem,
  FormControl,
  Button,
  CircularProgress,
  LinearProgress,
  Badge,
  Card,
  IconButton,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import DeleteIcon from "@mui/icons-material/Delete";
import axiosBackendApi from "../../shared/services/api/axiosBackendInstance";
import Message from "./components/message";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import Tooltip from '@mui/material/Tooltip';
import AudioFileOutlinedIcon from '@mui/icons-material/AudioFileOutlined';
import UploadForm from './components/uploadForm';
import ResultPercentage from './components/resultPercentage';
import ButtonDetection from './components/buttonDetection';
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});


const Audio = () => {
  const [language, setLanguage] = useState("");
  const [audioFile, setAudioFile] = useState<File | null>(null);
  const [audioFileSource, setAudioFileSource] = useState("");
  const [loading, setLoading] = useState(false);
  const [predictions, setPredictions] = useState<any>({});
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [showResult, setShowResult] = useState(false);
  const allowedAudioTypes = ["audio/mp3", "audio/wav", "audio/mpeg"];
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const showMessage = (newMessage: string) => {
    setMessage(newMessage);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    setLanguage(event.target.value);
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      if (!allowedAudioTypes.includes(file.type)) {
        showMessage("Invalid file type. Please upload a audio file.");
        return;
      }

      setAudioFile(file);

      const formData = new FormData();
      formData.append("file", file);
      setLoading(true);
      try {
        const response = await axiosBackendApi.post("audio/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setAudioFileSource(response.data.data);
      } catch (error) {
        console.error("File upload failed", error);
        showMessage("File upload failed");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleRemove = () => {
    setAudioFile(null);
    setAudioFileSource("");
    setPredictions({});
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const sendData = async () => {
    if (!language) {
      showMessage("Please Select Model Language");
      return;
    }

    if (!audioFileSource) {
      showMessage("Please upload an audio file.");
      return;
    }

    setLoading(true);
    setShow(true);

    const formData = new FormData();
    formData.append("file", audioFileSource);
    formData.append("selected_language", language);
    try {
      const response = await axiosBackendApi.post("audio", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
        setPredictions(response.data);
        setShowResult(true);
    } catch (err) {
      console.error("Error sending data", err);
      setPredictions({});
      setShowResult(false)

    } finally {
      setLoading(false);

    }
  };




  return (
    <Box mt={7}>
      <Card variant="outlined" sx={{ p: 3 }}>
        <UploadForm
          title="Detect Fake Audio"
          selectedLanguage={language}
          handleLanguageChange={handleLanguageChange}
          keyFile={audioFile}
          handleFileChange={handleFileChange}
          fileInputRef={fileInputRef}
          acceptedFormats="audio/*"
          maxFileSize="50MB"
          supportedFormats="MP3, WAV"
        />
        {audioFile && (
          <Box
            sx={{
              mt: 3,
              display: "flex",
              alignItems: "center",
              backgroundColor: "#f4f6f8", // Light background
              padding: "16px",
              borderRadius: "8px",
              border: "1px solid #d3daea",
            }}
          >
            {/* File Icon */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 50,
                height: 50,
                backgroundColor: "#e7ebf0", // Light gray background for the icon
                borderRadius: "8px",
                marginRight: 2, // Space between the icon and file details
              }}>
              <AudioFileOutlinedIcon sx={{ color: "#6c757d", fontSize: 32 }} />
            </Box>
            {/* File Details */}
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
                {audioFile.name}
              </Typography>
              <Typography variant="caption" sx={{ color: "#6c757d" }}>
                Size: {(audioFile.size / (1024 * 1024)).toFixed(2)} MB Modified Time:{" "}
                {new Date(audioFile.lastModified).toLocaleString()}
              </Typography>
            </Box>

            {/* Delete Icon */}
            <IconButton
              onClick={handleRemove}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 50,
                height: 50,
                backgroundColor: "#e7ebf0",
                borderRadius: "8px",
                marginLeft: 2,
              }}
            >
              <DeleteIcon sx={{ color: "#ff6b6b", fontSize: 24 }} />
            </IconButton>
          </Box>
        )}

        {audioFileSource && (
          <Box mt={3}>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Preview:
            </Typography>
            <audio src={audioFileSource} controls style={{ width: "100%" }} />
          </Box>
        )}

        <ButtonDetection onClick={sendData} disabled={!audioFile} loading={loading} keyFile={predictions?.ensemble_label}/>

        {showResult && predictions?.ensemble_label && (
          <ResultPercentage
            key={''}
            label={predictions?.ensemble_label}
           probability={predictions.Prob}
          checkLabel="Real Audio"
          />
        )}
      </Card>
      <Message message={message} open={open} onClose={handleClose} />
    </Box>
  );
};

export default Audio;




