import React from 'react';
import { Button, CircularProgress } from '@mui/material';

const ButtonDetection = ({
                        onClick,
                        loading,
                        disabled,
                         keyFile

                      }) => {
  return (
    <Button
      variant="contained"
      color='primary'
      onClick={onClick}
      disabled={disabled || keyFile|| loading}
      sx = { {mt: 3, color: '#fff !important'} }
    >
      <span style={{display:'flex'}}>
        {loading ?<> Ai Detect <CircularProgress size={24} sx={{ml:2 ,color:'#fff !important'}} /></> : "Ai Detect"}
      </span>
    </Button>
  );
};

export default ButtonDetection;
