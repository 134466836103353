// @ts-nocheck
import NewsLetters from "../images/icons/envelope.svg";

export const ROUTES = {
  search: '/search',
  Chat:'/chat/:id?',
  Online: '/chat-rag-online',
  Offline: '/chat-rag-offline',
  Pdf: '/chat-files',
  SummarizerA: '/chat-brief7B',
  SummarizerB: '/chat-brief13B',
  visualisation: '/visualizations',
  newsLetter: '/Newsletters',
  newsLetter: '/Newsletters',
  scraping : '/scraping',
  unsubscribeNewsletter: '/unsubscribe-newsletter',
  users: '/users',
  profile: '/profile',
  CreateNewsLetter: '/create-news-letter',
  notFound: '/404',
  explore: '/explore',
  home: '',
  newNewsLetter: '/NewsLetters/:id?',
  matrix: '/matrix',
  matrixLogin: '/matrix/login',
  matrixPersonal: '/matrix/personal',
  matrixKnowledge: '/matrix/knowledge',
  matrixAdditionalInfo: '/matrix/additional-information',
  matrixOverview: '/matrix/overview',
  matrixFinal: '/matrix/ready',
  myProfile: '/my-profile',
  layout: '/layout',
  admin: '/admin',
  adminLogin: '/admin/login',
  login: '/login',
  forgetPassword: '/account-recovery',
  passwordReset: '/password-reset',
  adminPanel: '/admin/panel',
  adminNewEntry: '/admin/new-entry',
  activityLog: '/activityLog',
  activityView: '/activityLog/:activityId',
  resetPassword: '/resetPassword',
  ResearchAgent: '/researchAgent',
  viewSurvey: '/viewSurvey',
  register: '/register',
  domains: '/domains',
  Text: '/deep-fake/text',
  audio: '/deep-fake/audio',
  image: '/deep-fake/image',
  video: '/deep-fake/video',
  media: '/deep-fake/multi-media',
  newsAgent:'/newsAgent',
  newNewsAgent: '/NewsAgent/:id?',
};

export const INITIAL_ACTIVE_QUADRANT = 2;
