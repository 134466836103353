// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, IconButton, CardMedia, Grid, Modal, Box, Tooltip } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import Subscribe from './subscribe';
import Recommendations from './Recommendations';
import cardImage from './../../images/newsletter.png';
import { useParams, Link } from 'react-router-dom';
import axiosBackendApi from '../../shared/services/api/axiosBackendInstance';
import CloseIcon from '@mui/icons-material/Close';
import { NewsletterChat } from './newsletter-chat.component';
import SearchLoadingImg from '../../images/loading.gif';
import defaultImage from '../../images/related-news-footer-article-default 1.png';
import Chat from '../../images/Message.svg';
import Breadcrumb from './breadcrumb'; // Import the Breadcrumb component
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import Alert from '@mui/material/Alert';
import SearchAppBar from '../layout/searchInput';
import DomainsSelect from './DomainsSelect';

import { Button, Chip } from '@mui/material';
import Pagination from '@mui/material/Pagination';

const NewsLetterDetail = () => {
  const { id, articleId } = useParams();
  const SEARCH_URL = process.env.REACT_APP_WEB_API;
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [openChat, setOpenChat] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedCard, setSelectedCard] = useState(null);
  const [domains, setDomains] = useState([]);
  const [query, setQuery] = useState('');
  const [domain, setDomain] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10; // Adjust as needed
  const [open, setOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState('');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChoose = (item) => {
    setSelectedItems(item);
    handleDomains(item);
  };

  const handleClear = () => {
    setSelectedItems('');
    handleDomains(''); // Clear the domain filter when the selection is cleared
  };

  const handleCloseChat = () => setOpenChat(false);

  const fetchArticles = (search = '', sub_filed = '',page = 1) => {
    setLoading(true);
    let query = [];
    if (sub_filed !== '') {
      query.push(`Sub_Field:${sub_filed}`);
    }
    if (search !== '') {
      query.push(`Title:${search}`);
    }
    const queryString = query.join(';');
    axiosBackendApi
      .get(`${SEARCH_URL}/newsletters-reports/${articleId}/articles`, {
        params: {
          search: queryString,
          searchJoin: 'and',
          target:'news',
          page: page,
          perPage: itemsPerPage,
        },
      })
      .then((response) => {
        setArticles(response.data.data.articles);
        setFilteredArticles(response.data.data.articles);
        setTotalPages(response.data.data.last_page);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchArticles();
  }, [id]);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    fetchArticles(query,domain, value);
  };
  const handleSearch = (search) => {
    fetchArticles(search);
  };
  const handleOpenChat = (card) => {
    setSelectedCard(card);
    setOpenChat(true);
  };
  const handleDomains = (search) => {
    fetchArticles(query, search);
  };

  useEffect(() => {
    axiosBackendApi
      .get(`${SEARCH_URL}/newsletters-reports/${articleId}/fields`,{
        params: {

          target:'news',
        },
      })
      .then((response) => {
        setDomains(response.data.data);
      })
      .catch((error) => {
        console.error('Error fetching domains:', error);
      });
  }, [articleId]);

  // Define breadcrumb items
  const breadcrumbItems = [
    { label: 'NewsAgent', path: '/newsagent' },
    { label: 'Reports', path: `/NewsAgent/${id}` },
    { label: 'NewsAgent Detail', isActive: true },
  ];

  return (
    <Box sx={{ flexGrow: 1, marginTop: 10 }}>
      {/* Render Breadcrumb */}
      <Breadcrumb items={breadcrumbItems} />
      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: '16px',
            padding: '0 20px',
          }}
        >
          <div className="flex gap-2 items-center w-full flex-wrap newsletter__header">
            <div className="min-w-50">
              <SearchAppBar onSearch={handleSearch} placeholder="search..." />
            </div>
          </div>
          <Grid item xs={12}>
            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" minHeight="400px">
                <img src={SearchLoadingImg} className="loading-img" alt="Loading" />
              </Box>
            ) : filteredArticles.length === 0 ? (
              <Alert
                severity="warning"
                sx={{ display: 'flex', justifyContent: 'center', width: '100%', fontWeight: 'bold' }}
              >
                No Result Found
              </Alert>
            ) : (
              <Box
                sx={{
                  width: '100%',
                  display: 'grid',
                  gridTemplateColumns: {
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(1, 1fr)',
                    md: 'repeat(2, 1fr)',
                    lg: 'repeat(3, 1fr)',
                  },
                  gap: 3,
                }}
              >
                {filteredArticles.map((item, index) => (
                  <Card
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: 3,
                      padding: 1,
                      borderRadius: '10px !important',
                    }}
                  >

                    <Grid className="post" container>
                      <Grid item xs={12} sm={12} width={'100%'}>
                        <Link to={`/newsagents/${id}/NewsAgentDetail/${articleId}/show/${item.id}`}>
                          <CardMedia
                            component="img"
                            sx={{
                              minWidth: '100% !important',
                              height: '250px',
                              flexShrink: 0,
                              padding: 1,
                              borderRadius: 10,
                            }}
                            image={item.image_url?.startsWith('https://') ? item.image_url : defaultImage}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = defaultImage;

                            }}
                            alt="NewsAgent image"
                          />
                        </Link>
                        <Typography
                          variant="h5"
                          component="div"
                          className="post__title"
                          sx={{
                            fontWeight: 'bold',
                            paddingLeft: 1,
                            width: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            height: '30px',
                            marginBottom: '10px',
                          }}
                        >
                          <Link
                            to={`/newsagents/${id}/NewsAgentDetail/${articleId}/show/${item.id}`}
                            sx={{ textDecoration: 'none !important' }}
                          >
                            {item.title}
                          </Link>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} width={'100%'}>
                        <Box
                          className="flex-wrap gap-2"
                          sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px 5px' }}
                        >
                          <Box className="flex-wrap gap-2" sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                              variant="body2"
                              component="div"
                              color="textSecondary"
                              mb={2}
                              sx={{
                                padding: '5px 10px',
                                borderRadius: 4,
                                backgroundColor: 'rgba(8, 89, 80,0.05)',
                                color: 'rgb(8, 89, 80) !important',
                                fontWeight: 'bold',
                                marginBottom: 0,
                              }}
                            >
                              <span className={'sub-titles-color'}> {item.field}</span>
                            </Typography>
                            <Typography
                              variant="body2"
                              component="div"
                              color="textSecondary"
                              mb={2}
                              sx={{
                                padding: '5px 10px',
                                marginBottom: 0,
                                borderRadius: 4,
                                backgroundColor: 'rgba(8, 89, 80,0.05)',
                                color: 'rgb(8, 89, 80) !important',
                                fontWeight: 'bold',
                              }}
                            >
                              <span className={'sub-titles-color'}> {item.sub_field}</span>
                            </Typography>
                            <Typography
                              variant="body2"
                              component="div"
                              color="textSecondary"
                              mb={2}
                              sx={{
                                padding: '5px 10px',
                                marginBottom: 0,
                                borderRadius: 4,
                                backgroundColor: 'rgba(8, 89, 80,0.05)',
                                color: 'rgb(8, 89, 80) !important',
                                fontWeight: 'bold',
                              }}
                            >
                              <span className={'sub-titles-color'}> {item.published_at}</span>
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Tooltip title={'chat with ai'}>
                              <IconButton aria-label="comment" onClick={() => handleOpenChat(item)}>
                                <svg width="24" height="24" viewBox="0 0 16 17" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M8.00121 8.70347C7.948 8.70263 7.89728 8.68079 7.86011 8.64272C7.82293 8.60464 7.80231 8.55342 7.80274 8.50021C7.80274 8.38817 7.89237 8.29694 8.00121 8.29694"
                                    stroke="#979e9e" strokeWidth="0.96" strokeLinecap="round" strokeLinejoin="round" />
                                  <path
                                    d="M4.55625 8.69707C4.53018 8.69686 4.50442 8.69152 4.48042 8.68135C4.45642 8.67119 4.43466 8.65639 4.41638 8.63781C4.3981 8.61923 4.38365 8.59724 4.37387 8.57308C4.3641 8.54892 4.35917 8.52307 4.35938 8.497C4.35938 8.38657 4.44741 8.29694 4.55625 8.29694"
                                    stroke="#979e9e" strokeWidth="0.96" strokeLinecap="round" strokeLinejoin="round" />
                                  <path
                                    d="M11.4488 8.69707C11.4228 8.69686 11.397 8.69152 11.373 8.68135C11.349 8.67119 11.3272 8.65639 11.309 8.63781C11.2907 8.61923 11.2762 8.59724 11.2665 8.57308C11.2567 8.54892 11.2517 8.52307 11.252 8.497C11.252 8.38657 11.34 8.29694 11.4488 8.29694"
                                    stroke="#979e9e" strokeWidth="0.96" strokeLinecap="round" strokeLinejoin="round" />
                                  <path
                                    d="M8.00586 8.70347C8.03234 8.70326 8.05853 8.69784 8.08291 8.68751C8.1073 8.67718 8.12942 8.66215 8.14799 8.64328C8.16657 8.6244 8.18125 8.60205 8.19119 8.5775C8.20113 8.55295 8.20614 8.52669 8.20593 8.50021C8.20614 8.47372 8.20113 8.44746 8.19119 8.42291C8.18125 8.39836 8.16657 8.37601 8.14799 8.35713C8.12942 8.33826 8.1073 8.32323 8.08291 8.3129C8.05853 8.30257 8.03234 8.29715 8.00586 8.29694"
                                    stroke="#979e9e" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                                  <path
                                    d="M4.55664 8.69707C4.5827 8.69686 4.60847 8.69152 4.63247 8.68135C4.65647 8.67119 4.67823 8.65639 4.69651 8.63781C4.71479 8.61923 4.72923 8.59724 4.73901 8.57308C4.74879 8.54892 4.75372 8.52307 4.75351 8.497C4.75372 8.47094 4.74879 8.44509 4.73901 8.42093C4.72923 8.39677 4.71479 8.37477 4.69651 8.3562C4.67823 8.33762 4.65647 8.32282 4.63247 8.31265C4.60847 8.30249 4.5827 8.29715 4.55664 8.29694"
                                    stroke="#979e9e" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                                  <path
                                    d="M11.4492 8.69707C11.4753 8.69686 11.501 8.69152 11.525 8.68135C11.549 8.67119 11.5708 8.65639 11.5891 8.63781C11.6074 8.61923 11.6218 8.59724 11.6316 8.57308C11.6414 8.54892 11.6463 8.52307 11.6461 8.497C11.6463 8.47094 11.6414 8.44509 11.6316 8.42093C11.6218 8.39677 11.6074 8.37477 11.5891 8.3562C11.5708 8.33762 11.549 8.32282 11.525 8.31265C11.501 8.30249 11.4753 8.29715 11.4492 8.29694"
                                    stroke="#979e9e" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                                  <path
                                    d="M8.0035 1.29779C6.69373 1.29823 5.4088 1.65527 4.28655 2.33059C3.16431 3.00591 2.2471 3.97403 1.63334 5.13109C1.02159 6.28785 0.737747 7.58984 0.812505 8.89626C0.887262 10.2027 1.31777 11.4638 2.05748 12.5432L0.801055 15.7027L4.1622 14.5695C5.10682 15.162 6.17597 15.5274 7.28565 15.637C8.39533 15.7466 9.51531 15.5974 10.5576 15.2012C11.5999 14.8049 12.5361 14.1723 13.2926 13.3531C14.0492 12.534 14.6055 11.5505 14.9179 10.4801C15.2277 9.40994 15.2839 8.28233 15.0822 7.18663C14.8806 6.09093 14.4264 5.05727 13.7559 4.16757C13.0849 3.27622 12.2161 2.553 11.2178 2.05497C10.2195 1.55694 9.11912 1.29773 8.0035 1.29779Z"
                                    stroke="#979e9e" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={'Read More'}>
                              <Link to={`/newsagents/${id}/NewsAgentDetail/${articleId}/show/${item.id}`}>
                                <ArrowCircleRightOutlinedIcon
                                  sx={{
                                    fontSize: 28,
                                    stroke: '#979E9E',
                                    fill: '#979E9E',
                                    strokeWidth: '0px',
                                    marginTop: 1,
                                  }}
                                />
                              </Link>
                            </Tooltip>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} marginTop="10px" marginBottom="10px">
                        <CardContent
                          sx={{
                            padding: 1,
                            paddingBottom: '0 !important',
                            height: '60px',
                            marginBottom: '10px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            // whiteSpace: 'nowrap',
                          }}
                        >
                          <Typography
                            sx={{
                              mb: 1.5,
                            }}
                            color="text.secondary"
                            padding={0}
                          >
                            {item.summary.replace(/[*\/-]/g, '')}
                          </Typography>
                        </CardContent>
                      </Grid>
                    </Grid>
                  </Card>
                ))}
              </Box>
            )}
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }} className="pagination-wrapper">
            {!loading && filteredArticles.length > 0 && (
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                variant={'outlined'}
                size="large"
              />
            )}
          </Box>
        </Box>
      </Grid>
      <Modal
        open={openChat}
        onClose={handleCloseChat}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description scroll"
        className={'chat_modal_all'}
        sx={{
          width: "70%",
          marginInline: "auto",
          marginTop: 5,

        }}
      >
        <>
          <Box
            sx={{
              background: '#fff',
              borderRadius: '10px',
              outline: 'none',
            }}
            className='modal-large'
          >
            <Typography
              className="modal-modal-title d-flex justify-content-between align-items-baseline"
              variant="h6"
              component="h2"
              sx={{ display: 'flex', justifyContent: 'space-between', overflowX: 'auto' }}
            >
              Chat with {selectedCard?.title}
              <IconButton size="small" aria-label="close" color="inherit" ml={1} onClick={handleCloseChat}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Typography>
            <NewsletterChat document={selectedCard} type={'newsletter'} />

          </Box>
        </>
      </Modal>
    </Box>
  );
};

export default NewsLetterDetail;
