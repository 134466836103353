import React from 'react';
import { Box, Typography } from '@mui/material';

const Result = ({ label, probability, checkLabel = "Human" }) => {
  const isReal = label === checkLabel;
  const borderColor = isReal ? "green" : "red";
  const Color = isReal ? "rgba(0, 128, 0,0.2)" : "rgba(255, 0, 0,0.2)";
  const textClass = isReal ? 'percentage-human' : 'percentage-machine';
  const progress = Math.round(probability * 100);

  return (
    <Box style={{ textAlign: "center", margin: "16px 0" }}>
      <span className={textClass}>{label}</span>
      <Box
        style={{
          width: "150px",
          height: "150px",
          borderRadius: "50%",
          margin: "0 auto",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: Color,
        }}
      >
        {/* Progress Circle */}
        <Box
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            background: `conic-gradient(${borderColor} ${progress}%, transparent ${progress}% 100%)`,
          }}
        />
        {/* Inner Circle to Cover the Center */}
        <Box
          style={{
            width: "120px",
            height: "120px",
            borderRadius: "50%",
            backgroundColor: "white",
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h4" style={{ color: "gray" }}>
            <Box
              sx={{
                fontSize: 25,
                color: borderColor,
              }}
            >
              <span className={textClass}>{progress}%</span>
            </Box>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Result;
