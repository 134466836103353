import Echo from "laravel-echo";
import Pusher from "pusher-js";
window.Pusher = Pusher;
const echo = new Echo({
  broadcaster: "pusher",
  key: "k987654321",
  cluster: "mt1",
  forceTLS: true,
  wsHost: "ai-platform.wakeb.tech",
  wsPort: 8000,
  wssPort: 8000,
  disableStats: true,
//   key: process.env.VUE_APP_PUSHER_KEY,
//   cluster: "mt1",
//   forceTLS: false,
//   wsHost: window.location.hostname,
//   wsPort: 6001,
//   wssPort: 6001,
  transports: ["websocket", "polling", "flashsocket"],
  enabledTransports: ["ws", "wss"],
});
export default echo;
