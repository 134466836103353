// @ts-nocheck
import {createSlice} from '@reduxjs/toolkit';
import {fetchSessionById, deleteSession, fetchSessions, createSession} from '../actions/sessionsActions';

const sessionsSlice = createSlice({
  name: 'sessions', initialState: {
    sessions: [], loading: false, error: null, session: {logs: []}, created: false
  }, reducers: {
    clearSession: (state) => {
      state.session = {logs: []};
    }, newRegenerateLog: (state, action) => {
      state.session.logs.forEach(log => {
        if (log.id === action.payload.logId) {
          if (action.payload.answers.length === log.answers.length) {
            log.answers.push({
              "answer": action.payload.chunks, "model": action.payload.model, "created_at": new Date().toString() // TODO: change to actual time
            })
          } else {
            let len = log.answers.length - 1;
            log.answers[len] = ({
              "answer": action.payload.chunks, "model": action.payload.model, "created_at": new Date().toString() // TODO: change to actual time
            })
          }
        }
      });
    }, patchAnswer: (state, action) => {
      state.session.logs.forEach(log => {
        if (log.id === action.payload.logId) {
          log.answers.forEach(answer => {
            if (answer.id === action.payload.answerId) {
              answer.liked = action.payload.liked;
            }
          });
        }
      });
    }, patchLog: (state, action) => {
      state.session.logs.forEach(log => {
        if (log.id === action.payload.logId) {
          log.answers = action.payload.answers;
        }
      });
    }, setLogId: (state, action) => {

      let len = state.session.logs.length - 1;
      state.session.logs[len] = {
        ...state.session.logs[len],
        id: action.payload.log.id,
        answers : action.payload.log.answers
      }
    }, newLog: (state, action) => {
      let len = state.session.logs.length - 1;
      let answers = [{
        "answer": action.payload?.chunks,
        "model": action.payload.model,
        "created_at": new Date().toString(),
        ...(action.payload.model === 'chatRAGOnline' && {
          sources: Object.hasOwn(action.payload, 'sources')
            ? action.payload.sources
            : state.session.logs[len].answers[0]?.sources
        })
      }];
      state.session.logs[len] = {
        question: state.session.logs[len].question,
        answers: answers
      }
    }, newQuestion: (state, action) => {
      state.session.logs.push({
        question: action.payload, answers: []
      })
    },
  }, extraReducers: (builder) => {
    builder
      .addCase(createSession.pending, state => {
        state.loading = true;
      })
      .addCase(createSession.fulfilled, (state, action) => {
        const logs = state.session.logs;
        state.session = (action.payload);
        state.session.logs = [{
          question: state.session.title
        }]
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchSessions.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSessions.fulfilled, (state, action) => {
        state.sessions = (action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchSessions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchSessionById.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSessionById.fulfilled, (state, action) => {
        state.session = (action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchSessionById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }).addCase(deleteSession.fulfilled, (state, action) => {

      // state.sessions = state.sessions.filter(session => session.id !== action.payload);
    }).addCase(deleteSession.rejected, (state, action) => {

      // state.sessions = state.sessions.filter(session => session.id !== action.payload);
    });
    // .addCase(updateSessionTitle.fulfilled, (state, action) => {
    //   const index = state.sessions.findIndex(session => session.id === action.payload.id);
    //   if (index !== -1) {
    //     state.sessions[index].title = action.payload.title;
    //   }
    // })
  }
});


export default sessionsSlice.reducer;
