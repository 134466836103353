import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  MenuItem,
  FormControl,
  Button,
  CircularProgress,
  LinearProgress,
  Badge,
  Card,
  IconButton, TextField,
} from '@mui/material';
import Select, { SelectChangeEvent } from "@mui/material/Select";
import DeleteIcon from "@mui/icons-material/Delete";
import axiosBackendApi from "../../shared/services/api/axiosBackendInstance";
import Message from "./components/message";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Tooltip from '@mui/material/Tooltip';
import VideoFileOutlinedIcon from '@mui/icons-material/VideoFileOutlined';
import UploadForm from './components/uploadForm';
import ResultPercentage from './components/resultPercentage';
import ButtonDetection from './components/buttonDetection';
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Media = () => {
  const [language, setLanguage] = useState("");
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [videoFileSource, setVideoFileSource] = useState("");
  const [loading, setLoading] = useState(false);
  const [predictionsUrl, setPredictionsUrl] = useState<any>({});
  const [predictionsVideoUrl, setPredictionsVideoUrl] = useState<any>({});
  const [predictions, setPredictions] = useState<any>({});
  const [predictionsVideo, setPredictionsVideo] = useState<any>({});
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [showResult, setShowResult] = useState(false);
  const allowedVideoTypes = ["video/mp4", "video/webm", "video/ogg"];
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [value, setValue] = useState(0);
  const showMessage = (newMessage: string) => {
    setMessage(newMessage);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    setLanguage(event.target.value);
  };
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      if (!allowedVideoTypes.includes(file.type)) {
        showMessage("Invalid file type. Please upload a video file.");
        return;
      }

      setVideoFile(file);

      const formData = new FormData();
      formData.append("file", file);
      setLoading(true);
      try {
        const response = await axiosBackendApi.post("media/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setVideoFileSource(response.data.data);
      } catch (error) {
        console.error("File upload failed", error);
        showMessage("File upload failed");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleRemove = () => {
    setVideoFile(null);
    setVideoFileSource("");
    setPredictions({});
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const sendData = async () => {
    if (!language) {
      showMessage("Please Select Model Language");
      return;
    }

    if (!videoFileSource) {
      showMessage("Please upload an audio file.");
      return;
    }

    setLoading(true);
    setShow(true);

    const formData = new FormData();
    formData.append("file", videoFileSource);
    formData.append("selected_language", language);

    try {
      const response = await axiosBackendApi.post("media", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setPredictions(response.data.audio_analysis);
      setPredictionsVideo(response.data.video_analysis);
      console.log(response.data.audio_analysis);
      setShowResult(true);  // Show result after successful response
    } catch (err) {
      console.error("Error sending data", err);
      setPredictions({});
      setShowResult(false);
    } finally {
      setLoading(false);
    }
  };

  const sendDataUrl = async () => {
    if (!youtubeUrl.trim()) {
      showMessage("Please enter a valid YouTube URL.");
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("youtube_url", youtubeUrl);
    formData.append("selected_language", language);
    try {
      const response = await axiosBackendApi.post("media", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setPredictionsUrl(response.data.audio_analysis);
      setPredictionsVideoUrl(response.data.video_analysis);
      setShowResult(true);
    } catch (err) {
      setPredictions({});
      setShowResult(false);
      showMessage("Server error. try again later.");
    } finally {
      setLoading(false);
    }
  };

  // @ts-ignore
  return (
    <Box mt={7}>
      <Card variant="outlined" sx={{ p: 3 }}>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }} className={'upload-title'}>
            Detect Fake Multi Modal
          </Typography>
        </Box>
        <Box sx={{  }}>

          <Tabs
            value={value}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
            className="profile__tabs"
            sx={{
              backgroundColor: 'rgb(8, 89, 80,0.07) !important',
              width: { xs: '100%', sm: 'fit-content' }, // Full width on mobile, fit content on larger screens
              borderRadius: '10px',
              marginInline: { xs: 0, sm: '24px' }, // Remove margin on mobile, keep it on larger screens
              padding: '3px',
              border: '2px solid #EAECF0',
            }}
          >
            <Tab
              label={'Upload Video'}
              {...a11yProps(0)}
              sx={{
                paddingInline: { xs: 2, sm: 5 }, // Smaller padding on mobile, larger padding on larger screens
                textAlign: 'center', // Center align text on mobile
                fontSize: { xs: '12px', sm: 'inherit' }, // Smaller font size on mobile
              }}
            />
            <Tab
              label={'YouTube URL'}
              {...a11yProps(1)}
              sx={{
                color: '#000 !important',
                paddingInline: { xs: 2, sm: 5 }, // Smaller padding on mobile, larger padding on larger screens
                textAlign: 'center', // Center align text on mobile
                fontSize: { xs: '12px', sm: 'inherit' }, // Smaller font size on mobile
              }}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <UploadForm
            title=""
            selectedLanguage={language}
            handleLanguageChange={handleLanguageChange}
            keyFile={videoFile}
            handleFileChange={handleFileChange}
            fileInputRef={fileInputRef}
            acceptedFormats="video/*"
            maxFileSize="100MB"
            supportedFormats="MP4, WebM, OGG"
          />
          {videoFile && (
            <Box
              sx={{
                mt: 3,
                display: "flex",
                alignItems: "center",
                backgroundColor: "#f4f6f8", // Light background
                padding: "16px",
                borderRadius: "8px",
                border: "1px solid #d3daea",
              }}
            >
              {/* File Icon */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 50,
                  height: 50,
                  backgroundColor: "#e7ebf0", // Light gray background for the icon
                  borderRadius: "8px",
                  marginRight: 2, // Space between the icon and file details
                }}>
                <VideoFileOutlinedIcon  sx={{ color: "#6c757d", fontSize: 32 }} />
              </Box>
              {/* File Details */}
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
                  {videoFile.name}
                </Typography>
                <Typography variant="caption" sx={{ color: "#6c757d" }}>
                  Size: {(videoFile.size / (1024 * 1024)).toFixed(2)} MB Modified Time:{" "}
                  {new Date(videoFile.lastModified).toLocaleString()}
                </Typography>
              </Box>

              {/* Delete Icon */}
              <IconButton
                onClick={handleRemove}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 50,
                  height: 50,
                  backgroundColor: "#e7ebf0",
                  borderRadius: "8px",
                  marginLeft: 2,
                }}
              >
                <DeleteIcon sx={{ color: "#ff6b6b", fontSize: 24 }} />
              </IconButton>
            </Box>
          )}

          {videoFileSource && (
            <Box mt={3}>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Preview:
              </Typography>
              <video src={videoFileSource} controls style={{ width: "70%" ,marginLeft:'15%',borderRadius:'20px'}}/>
            </Box>
          )}

          {/*<Button*/}
          {/*  variant="contained"*/}
          {/*  color="primary"*/}
          {/*  onClick={sendData}*/}
          {/*  disabled={loading || !videoFile || predictions.ensemble_label}*/}
          {/*  sx={{ mt: 3,color:'#fff' }}*/}

          {/*>*/}
          {/*  {loading ? <CircularProgress size={24} color="inherit" /> : "Ai Detect"}*/}
          {/*</Button>*/}
          <ButtonDetection onClick={sendData} disabled={!videoFile} loading={loading} keyFile={predictions?.ensemble_label}/>


          {predictions.ensemble_label && (
            <div style={{ display: 'flex', justifyContent: 'space-around',flexWrap:'wrap',marginTop:'50px',border:'1px solid #eee',padding:20,borderRadius:10 }}>
           <div>
             <div>Audio Result : </div>
             <ResultPercentage
               key={''}
               label={predictions.ensemble_label}
               probability={predictions.Prob}
               checkLabel="REAL"
             />
           </div>

           <div>
             <div>
               Video Result :
             </div>
             <ResultPercentage
               key={''}
               label={predictionsVideo.ensemble_label}
               probability={predictionsVideo.ensemble_confidence}
               checkLabel="REAL"
             />
           </div>
            </div>


          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <h3>Select a Model Language</h3>
          <FormControl sx={{ width: "100%" }}>
            <Select
              id="language-select"
              value={language}
              onChange={handleLanguageChange}
              sx={{
                marginBottom: 2,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#d3daea",
                  borderRadius: "8px",
                },
                '& .MuiInputBase-input': {
                  color: '#909baf !important',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#055b50',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#055b50',
                },
              }}
            >
              <MenuItem value="English">English</MenuItem>
              <MenuItem value="Arabic">Arabic</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <Typography variant="h6">Enter YouTube URL</Typography>
            <TextField
              fullWidth
              placeholder="Enter YouTube video URL"
              value={youtubeUrl}
              onChange={(e) => setYoutubeUrl(e.target.value)}
              sx={{
                marginBottom: 2,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: "#d3daea",
                    borderRadius: "8px",
                  },
                  '&:hover fieldset': {
                    borderColor: '#055b50',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#055b50',
                  },
                },
                '& .MuiInputBase-input': {
                  color: '#909baf !important',
                },
              }}
            />
          </FormControl>


          <ButtonDetection onClick={sendDataUrl} disabled={!youtubeUrl} loading={loading} keyFile={predictionsUrl?.ensemble_label}/>
          {predictionsUrl.ensemble_label && (
            <div style={{ display: 'flex', justifyContent: 'space-around',flexWrap:'wrap',marginTop:'50px',border:'1px solid #eee',padding:20,borderRadius:10 }}>
              <div>
                <div>Audio Result : </div>
                <ResultPercentage
                  key={''}
                  label={predictionsUrl.ensemble_label}
                  probability={predictionsUrl.Prob}
                  checkLabel="REAL"
                />
              </div>
              <div>
                <div>
                  Video Result :
                </div>
                <ResultPercentage
                  key={''}
                  label={predictionsVideo.ensemble_label}
                  probability={predictionsVideo.ensemble_confidence}
                  checkLabel="REAL"
                />
              </div>
            </div>
          )}
        </CustomTabPanel>
      </Card>
      <Message message={message} open={open} onClose={handleClose} />
    </Box>
  )
    ;
};
export default Media;




