import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  Button,
  CircularProgress,
  Tooltip,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  LinearProgress,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ReplayIcon from "@mui/icons-material/Replay";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import axios from "axios";
import axiosBackendApi from '../../shared/services/api/axiosBackendInstance';
import Message from './components/message';
import ResultPercentage from './components/resultPercentage';
import ButtonDetection from './components/buttonDetection';
interface Model {
  label: string;
  prob: number;
}

interface Result {
  text: string;
  models: Record<string, Model>;
}
const Text = () => {
  const [language, setLanguage] = useState("");
  const [textValue, setTextValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<Result[]>([]);
  const [paraphrasing, setParaphrasing] = useState("");
  const [paraphrasingLoading, setParaphrasingLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  // Handle language selection

  const showMessage = (newMessage: string) => {
    setMessage(newMessage);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  // Handle text input
  const handleTextChange = (event) => {
    setTextValue(event.target.value);
  };

  // API call for text analysis
  const handleSendData = async () => {
    if (!language) {
      showMessage("Please select a Modal language")
      return;
    }
    setParaphrasing("");
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("text", textValue);
      formData.append("version", language);

      const response = await axiosBackendApi.post("/text", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setResults(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error analyzing text:", error.message);
    } finally {
      setLoading(false);
    }
  };

  // API call for paraphrasing
  // API call for paraphrasing
  const handleParaphrasing = async () => {
    setResults([]);
    if (!language) {
      showMessage("Please select a language.");
      return;
    }

    if (language === "arabic") {
      setParaphrasingLoading(true);
      setTimeout(() => {
        setParaphrasing("اللغة العربية قيد التطوير");
        setParaphrasingLoading(false);
      }, 1000);
      return;
    }

    setParaphrasingLoading(true);
    setParaphrasing(""); // Clear the previous paraphrasing text
    try {
      const formData = new FormData();
      formData.append("text", textValue);
      formData.append("version", language);

      const response = await axiosBackendApi.post("/text/paraphrasing", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const fullText = response.data;
      let index = 0;
      const interval = setInterval(() => {
        if (index < fullText.length) {
          setParaphrasing((prev) => prev + fullText[index]);
          index++;
        } else {
          clearInterval(interval);
        }
      }, 30);
    } catch (error) {
      console.error("Error in paraphrasing:", error.message);
      showMessage("Error in paraphrasing");
    } finally {
      setParaphrasingLoading(false);
    }
  };
  // Reset input and results
  const handleReset = () => {
    setTextValue("");
    setResults([]);
    setParaphrasing("");
  };
  // Copy text to clipboard
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    showMessage("Copied to clipboard!");
  };
  const handleChange = (event: SelectChangeEvent) => {
    setLanguage(event.target.value);
  };
  // @ts-ignore
  return (
    <Box  mt={7} width={'100%'} sx={{
      boxShadow:"1px 1px 2px 2px #f1f1f1",
      padding:2,
      backgroundColor:'#f7f8fa',
    }}>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold" ,color:"#000 !important"}}>
          Detect Fake Text
        </Typography>
      </Box>
      <Box mb={2} className={'custom-form'}>
        <Typography sx={{fontSize:'13px'}}>Select Language</Typography>
        <FormControl sx={{ mt:1 ,width: '100%' }}>
          <Select
            labelId="language-select-label"
            id="language-select"
            value={language}
            onChange={handleChange}
            sx={{
              marginRight:1,
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#d3daea',
                borderRadius: '8px',
              },
              '& .MuiInputBase-input': {
                color: '#909baf !important',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#055b50',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#055b50 !important',
              },

            }}
          >
            <MenuItem value="english">English</MenuItem>
            <MenuItem value="arabic">Arabic</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Text Input */}
      <Box mb={2} position="relative">
        <Typography sx={{fontSize:'13px'}}>Enter your text</Typography>
        <TextField
          multiline
          minRows={4}
          fullWidth
          variant="outlined"
          value={textValue}
          onChange={handleTextChange}
          placeholder="Type here..."
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: "#d3daea",
                borderRadius: "8px",
              },
              '&:hover fieldset': {
                borderColor: '#055b50',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#055b50',
              },
            },
            '& .MuiInputBase-input': {
              color: '#909baf !important',
            },
          }}
        />
        {textValue && (
          <IconButton
            onClick={handleReset}
            style={{ position: "absolute", top: "35px", right: "5px",backgroundColor:'#fff' }}
          >
            <RefreshIcon />
          </IconButton>
        )}
      </Box>

      {/* Action Buttons */}
      <Box display="flex" gap={2} mb={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSendData}
          disabled={!textValue || loading}
        >
          {loading ? <>Ai Detect <CircularProgress size={24} sx={{mx:1}}  color="inherit" /></> : "Ai Detect"}
        </Button>


        <Tooltip title="Paraphrase">
          <Button
            variant="contained"
            color="primary"
            onClick={handleParaphrasing}
            disabled={!textValue || paraphrasingLoading}
          >
            {paraphrasingLoading ? (
                <>Paraphrase <CircularProgress size={24} sx={{mx:1}}  color="inherit" /></>

            ) : (
              "Paraphrase"
            )}
          </Button>
        </Tooltip>
      </Box>
      {/* Results Section */}
      {results.map((result, index) => (
        <Card
          key={index}
          style={{
            marginBottom: "16px",
            position: "relative",
            background:
              Object.values(result.models)[0]?.label === "Human"
                ? "#d4edda !important" // Light green for Human
                : "#f8d7da !important", // Light red for Machine
          }}
        >
          <CardHeader
            title={result.text}
            action={
              <Tooltip title="Close">
                <IconButton onClick={() => handleReset()}>
                  <HighlightOffIcon />
                </IconButton>
              </Tooltip>
            }
          />
          <CardContent>
            {Object.values(result.models).map((model: Model, i) => (
              <ResultPercentage
                key={i}
                label={model.label}
                probability={model.prob}
                checkLabel="Human"
              />
            ))}
          </CardContent>
        </Card>
      ))}

      {/* Paraphrasing */}
      {paraphrasing && (
        <Card>

          <CardContent>
            <Box >
              <Box width={'100%'} display={'flex'} justifyContent={'end'}>
                <Tooltip title="Copy">
                  <IconButton onClick={() => handleCopy(paraphrasing)}>
                    <FileCopyIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Reparaphrasing">
                  <IconButton onClick={handleParaphrasing}>
                    <ReplayIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Close">
                  <IconButton onClick={() => handleReset()}>
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Typography dir={'auto'} sx={{marginTop:3}}>{paraphrasing}</Typography>

            </Box>
          </CardContent>
        </Card>
      )}
      <Message message={message} open={open} onClose={handleClose} />
    </Box>
  );
};

export default Text;
