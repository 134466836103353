
import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import Link from '@mui/material/Link';
import { ROUTES } from '../../app.constants';
import { useAdminPanelContext } from '../../../shared/components/adminPanel/adminPanelContext';
import wakebLight from '../../../images/AI-Platform-Light-0.png';
import { LoginWrapper } from './login.styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import '@fontsource/inter';
import '@fontsource/karla';
import '@fontsource/cabin';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { Grid } from "@mui/material";


export const Register = ({ locale, messages }) => {
  const intl = useIntl();
  const history = useHistory();
  const { registerLoading, user, signUp, verifyRegistration, showOtp, otpErrorMessage, otpLoading, firstNameError, lastNameError,
    passwordError, emailError, resendCode, otpSendSuccess } = useAdminPanelContext();
  const location = useLocation();
  const [firstNameErrorFront, setFirstNameErrorFront] = React.useState('');
  const [lastNameErrorFront, setLastNameErrorFront] = React.useState('');
  const [passwordErrorFront, setPasswordErrorFront] = React.useState('');
  const nameRegex = /^[A-Za-z\s]+$/;
  const handleNameChange = (field, value) => {
    setUserPersonalInfo({ ...userPersonalInfo, [field]: value });

    if (!nameRegex.test(value)) {
      if (field === 'first_name') {
        setFirstNameErrorFront("Only letters are allowed");
      } else if (field === 'last_name') {
        setLastNameErrorFront("Only letters are allowed");
      }
    } else {
      if (field === 'first_name') {
        setFirstNameErrorFront('');
      } else if (field === 'last_name') {
        setLastNameErrorFront('');
      }
    }
  };
  useEffect(() => {
    if (location.pathname === '/register') {
      let appDiv = document.getElementById('app');
      appDiv.style.padding = '0';
      appDiv.style.marginLeft = '0';
      appDiv.style.maxWidth = '100%';
    }

    if (typeof user?.token != 'undefined' && user.token !== '') {
      history.push(ROUTES.search);
    }
  }, [user]);

  const [userPersonalInfo, setUserPersonalInfo] = React.useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirmation: '',
    _method: 'post',
  });

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [showPasswordConfirmation, setShowPasswordConfirmation] = React.useState(false);
  const handleClickShowPasswordConfirmation = () => setShowPasswordConfirmation((show) => !show);
  const handleMouseDownPasswordConfirmation = (event) => {
    event.preventDefault();
  };

  const [emailFrontError, setEmailFrontError] = React.useState("");

  const handleRegister = () => {
    const data = {
      first_name: userPersonalInfo.first_name,
      last_name: userPersonalInfo.last_name,
      email: userPersonalInfo.email,
      password: userPersonalInfo.password,
      password_confirmation: userPersonalInfo.password_confirmation,
      _method: 'post',
    };

     if(!firstNameErrorFront && !lastNameErrorFront && !passwordErrorFront) {
      signUp(data);
    }

  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleRegister();
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setUserPersonalInfo({ ...userPersonalInfo, email: value });

    if (!validateEmail(value)) {
      setEmailFrontError("Please enter a valid email address");
    } else {
      setEmailFrontError("");
    }
  };
  const [registerOtp, setRegisterOtp] = React.useState('');
  const handleVerify = () => {
    verifyRegistration({
      email: userPersonalInfo.email,
      email_verification_code: registerOtp
    });
  };

  const handleVerifyKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleVerify();
    }
  };

  const handleResend = () => {
    resendCode({
      email: userPersonalInfo.email,
    });
  };
  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setUserPersonalInfo({ ...userPersonalInfo, password: value });

    // Validate password length
    if (value.length < 8) {
      setPasswordErrorFront("Password must be at least 8 characters");
    } else {
      setPasswordErrorFront("");
    }
  };

  return (
    <LoginWrapper>
      {locale === 'en' ? (
        <Helmet
          title={intl.formatMessage({
            id: 'register',
            defaultMessage: 'Register',
            description: 'Register',
          })}
        />
      ) : (
        <Helmet
          title={intl.formatMessage({
            id: 'register',
            defaultMessage: 'انشاء حساب',
            description: 'انشاء حساب',
          })}
        />
      )}
      <div className="login-page">
        <video id="background-video" autoPlay loop muted poster="../../../images/login-bg-light.jpg">
          <source src="/login-bg-light.mp4" type="video/mp4" />
        </video>
        <div className="logo-side">
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <img src={wakebLight} alt="AIP" className="login-logo light ministry_logo" loading="lazy" />
          </div>
          <div>
            <Typography variant="h1">{messages.powerOfAI}</Typography>
            <Typography variant="h5">
              {messages.ChatWithTheSmartestAI}
              <br />
              {messages.ExperienceThePowerOfAIWithUs}
            </Typography>
          </div>
          <Typography variant="h6">{messages.MODAllRightReserved}</Typography>
        </div>
        <div className="login-content register">
          <Box
            sx={{
              width: 500,
              maxWidth: '100%',
            }}
          >
            {!showOtp ? (
              <div>
                <Typography variant="h5">{messages.letsCreateAccount}</Typography>
                <Typography variant="h6">{messages.LogInItWillEnableYouToGetAllTheFeatures}</Typography>
                <form onKeyPress={handleKeyPress} autoComplete="off" id="registerForm">
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {/*First Name*/}
                      <Grid item xs={12} lg={12}>
                        <FormControl sx={{ mb: 1, width: '100%' }}>
                          <label htmlFor="firstName" className="mb-1 d-block">{messages.FirstName}</label>
                          <OutlinedInput
                            id="firstName"
                            type="text"
                            value={userPersonalInfo.first_name}
                            placeholder={messages.FirstName}
                            onChange={(e) => handleNameChange('first_name', e.target.value)}
                          />
                          {firstNameErrorFront && <span className="text-danger mt-1">{firstNameErrorFront}</span>}
                          {firstNameError && <span className="text-danger mt-1">{firstNameError}</span>}
                        </FormControl>

                      </Grid>
                      {/*Last Name*/}
                      <Grid item xs={12} lg={12}>
                        <FormControl sx={{ mb: 1, width: '100%' }}>
                          <label htmlFor="lastName" className="mb-1 d-block">{messages.LastName}</label>
                          <OutlinedInput
                            id="lastName"
                            type="text"
                            value={userPersonalInfo.last_name}
                            placeholder={messages.LastName}
                            onChange={(e) => handleNameChange('last_name', e.target.value)}
                          />
                          {lastNameErrorFront && <span className="text-danger mt-1">{lastNameErrorFront}</span>}
                          {lastNameError && <span className="text-danger mt-1">{lastNameError}</span>}
                        </FormControl>
                      </Grid>
                      {/*Email*/}
                      <Grid item xs={12} lg={12}>
                        <FormControl sx={{ mb: 1, width: '100%' }}>
                          <label htmlFor="email" className="mb-1 d-block">
                            {messages.EmailAddress}
                          </label>
                          <OutlinedInput
                            type="text"
                            value={userPersonalInfo.email}
                            placeholder={messages.EmailAddress}
                            onChange={handleEmailChange}
                            sx={{
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: '1px solid #29504d',
                              },
                            }}
                          />
                          {emailError && <span className="text-danger mt-1">{emailError}</span>}
                          {emailFrontError && <span className="text-danger mt-1">{emailFrontError}</span>}
                        </FormControl>
                      </Grid>
                      {/*Password*/}
                      <Grid item xs={12} lg={12}>
                        <FormControl sx={{ mb: 1, width: '100%' }}>
                          <div className="d-flex align-items-center justify-content-between mb-1">
                            <label htmlFor="password">{messages.Password}</label>
                          </div>
                          <OutlinedInput
                            id="standard-adornment-password"
                            placeholder={messages.Password}
                            value={userPersonalInfo.password}
                            onChange={ handlePasswordChange}
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOutlinedIcon style={{ fontSize: '20px' }} />
                                  ) : (
                                    <VisibilityOffOutlinedIcon style={{ fontSize: '20px' }} />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            sx={{
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: '1px solid #29504d',
                              },
                            }}
                          />
                          {passwordErrorFront && <span className="text-danger mt-1">{passwordErrorFront}</span>}
                          {passwordError && <span className="text-danger mt-1">{passwordError}</span>}
                        </FormControl>
                      </Grid>
                      {/*Confirm Password*/}
                      <Grid item xs={12} lg={12}>
                        <FormControl sx={{ mb: 1, width: '100%' }}>
                          <div className="d-flex align-items-center justify-content-between mb-1">
                            <label htmlFor="confirmPassword">{messages.ConfirmPassword}</label>
                          </div>
                          <OutlinedInput
                            placeholder={'Confirm Password'}
                            id="confirmPassword"
                            type={showPasswordConfirmation ? 'text' : 'password'}
                            value={userPersonalInfo.password_confirmation}
                            onChange={(e) => {
                              setUserPersonalInfo({
                                ...userPersonalInfo,
                                password_confirmation: e.target.value,
                              });
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPasswordConfirmation}
                                  onMouseDown={handleMouseDownPasswordConfirmation}
                                  edge="end"
                                >
                                  {showPasswordConfirmation ? (
                                    <VisibilityOutlinedIcon style={{ fontSize: '20px' }} />
                                  ) : (
                                    <VisibilityOffOutlinedIcon style={{ fontSize: '20px' }} />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            sx={{
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: '1px solid #29504d',
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <div className="mt-2">
                    <Button
                      variant="contained"
                      color="success"
                      className={registerLoading ? 'cursor-not-allowed' : ''}
                      onClick={handleRegister}
                    >
                      {messages.createAccount}
                    </Button>
                  </div>
                </form>
                <Typography className="login-privacy-terms text-center" variant="body1">
                  {intl.formatMessage({
                    id: 'privacyTerms',
                    defaultMessage: 'By creating an account, you agree to our Terms and Privacy Policy.',
                    description: 'privacyTerms',
                  })}
                </Typography>
                <Link onClick={() => history.push(ROUTES.login)} className="text-primary d-block mb-2 mt-2 cursor-pointer">
                  {messages.alreadyHaveAnAccount}
                </Link>
              </div>
            ) : (
              <div>
                <Typography variant="h5">{messages.sendEmailVerificationCode}</Typography>
                <Typography variant="h6">{messages.EnterEmailVerificationCode}</Typography>
                <form onKeyPress={handleVerifyKeyPress}>
                  <FormControl sx={{ mb: 2, width: '100%' }}>
                    <div className="d-flex align-items-center justify-content-between mb-1">
                      <label htmlFor="password">{messages.verificationCode}</label>
                    </div>
                    <OutlinedInput
                      id="otp"
                      type="text"
                      value={registerOtp}
                      autoComplete='off'
                      placeholder={messages.enterCode}
                      onChange={(e) => setRegisterOtp(e.target.value)}
                    />
                  </FormControl>
                  <div className="d-flex align-items-center justify-content-between mb-1">
                    <div></div>
                    <Link onClick={handleResend} className="mb-0 mt-0 cursor-pointer">
                      {messages.resendCode}
                    </Link>
                  </div>
                  {otpErrorMessage.length ? <div className="text-danger">{otpErrorMessage}</div> : ''}
                  {otpSendSuccess ? <div className="text-info">{messages.codeResendSuccessfully}</div> : ''}
                  <div className="mt-2">
                    <Button
                      variant="contained"
                      color="success"
                      className={otpLoading ? 'cursor-not-allowed' : ''}
                      onClick={handleVerify}
                    >
                      {messages.verify}
                    </Button>
                  </div>
                </form>
              </div>
            )}
          </Box>
        </div>
      </div>
    </LoginWrapper>
  );
};
